@import 'styles/includes.scss';

.YouTubeVideo {
    $root: &;
    width: 100%;
    height: 100%;

    &__Ratio {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        position: relative;
        padding-top: 56.25%;

        &--Cover {
            padding-top: 0;
        }
    }

    &__Media {
        background-color: $colorBlack;
        width: 100%;
        height: 100%;

        #{$root}__Ratio--Cover & {
            object-fit: cover;
        }
    }

    &__Wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &--Gradient {
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.75) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
            }
        }

        &--Target {
            background-color: $colorGray10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 32px;
        }
    }

    &__Title {
        position: absolute;
        bottom: 8px;
        left: 8px;
        font-size: 1.6rem;
        color: $colorWhite;
        z-index: 1;
        line-height: 1.25;
        max-width: calc(100% - 16px);

        @include media(m) {
            bottom: 8px;
            left: 12px;
            max-width: calc(100% - 24px);
        }
    }

    &__Button {
        position: absolute;
        left: calc(50% - 28px);
        top: calc(50% - 28px);
        width: 56px;
        height: 56px;
        z-index: 1;
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;
        background-color: transparent;
        background-image: url('/img/youtube-play.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @include media(m) {
            left: calc(50% - 40px);
            top: calc(50% - 40px);
            width: 80px;
            height: 80px;
        }
    }

    &__TargetTitle {
        font-family: $fontLight;
        font-size: 1.8rem;
        line-height: 1.32;
        color: inherit;
    
        @include media(m) {
            font-size: 2.0rem;
        }
        text-align: center;
        margin-bottom: 24px;
    }

    &__TargetButtonWrap {
        display: flex;
        justify-content: center;
    }
}