@import '../../styles/includes';

.YouTubeVideoList {
    &__Title {
        @extend %h2;
        margin-bottom: 24px;
    }

    &__Preamble {
        @extend %preamble;
    }

    &__Content {
        @include media(m) {
            max-width: 628px;
            padding-right: 24px;
        }
    }

    &__Container {
        @extend %container;
    }

    &__Row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 24px;

        // @include media(m) {
        //     margin-bottom: 56px;
        // }
    }

    &__Button {
        &--Mobile {
            display: block;

            @include media(m) {
                display: none;
            }
        }

        &--Desktop {
            display: none;

            @include media(m) {
                display: block;
            }
        }
    }

    &__List {
        @include media(m) {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-auto-flow: dense;
            grid-auto-rows: minmax(calc(((100% - (5 * 24px)) / 6) * calc(9 / 16)), auto);
            grid-gap: 24px;
        }
    }

    &__Item {
        margin-bottom: 32px;
        box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);


        @include media(m) {
            margin-bottom: 0;
            box-shadow: none;

            &--1 {
                grid-column: span 4;
                grid-row: span 2;
            }

            &--2 {
                grid-column: span 2;
            }

            &--3 {
                grid-column: span 2;
            }
        }
    }
}